
import { AppContext } from './context/AppContext';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import * as THREE from 'three'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'
import ShoppingNav from './components/ShoppingNav.jsx'
import ExploreNav from './components/ExploreNav.jsx'
import Brandbar from './components/Brandbar.jsx'
import Overlay from './components/Overlay.jsx'
import Static from './components/Static.jsx'
import Footer from './components/static/Footer.jsx'
import Instructions from './components/Instructions.jsx'
import Helper from './components/Helper.jsx'
import { TVController } from './components/VideoComponents.jsx'
import Popup from './components/Popup.jsx'

import Fun99 from './pages/Fun99.jsx';

export default function App() {
  const { isFullscreen, isMobile } = React.useContext(AppContext);
  const mainContentRef = React.useRef(null);


  return (
    <Routes> {/* Add Routes */}
    <Route path="/" element={
    <>
      <Overlay />
      <div id="experience-canvas" className={isFullscreen ? 'fixed' : 'static'}>
        <Canvas
          gl={{
            antialias: true,
            toneMapping: THREE.NoToneMapping,
            toneMappingExposure: 0.9,
            outputEncoding: THREE.sRGBEncoding
          }}
          camera={{
            fov: isMobile ? 70 : 40,
            near: 0.1,
            far: 700,
            position: [0, 0, 0]
          }}
        >
          <Experience />
        </Canvas>
      </div>
      <Brandbar />
      <Popup />
      <ShoppingNav />
      {!isFullscreen && (
        <>
        <div id="static" ref={mainContentRef} tabIndex={-1}>
          <Static staticRef={mainContentRef} />
        </div>
        <Footer />
        </>
      )}
      <ExploreNav />
      <Instructions />
        <TVController />
      <Helper />
    </>
    } />
    <Route path="/fun99/" element={<Fun99 />} />
  </Routes>
  )
}

