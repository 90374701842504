
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import StaticBrandbar from '../components/static/StaticBrandbar';
import StaticCallout from '../components/static/StaticCallout';
// Import Swiper React components
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

// import styles
import '../styles/static/fun99.css';


export default function Fun99() {
  const vacationData = [

    {
      thumbnail: '/fun99/thumb-orlando.jpg',
      title: <><span>The most fun</span> <span>in the most magical</span> <span>amusement park</span></>,
      location: 'Orlando, FL',
      imageAlt: 'Orlando theme park at night with fireworks',
      details: 
        <>
          <li>Flight travel (private jet not included … maybe next time)</li>
          <li>Three nights at a luxury hotel</li>
          <li>Two-day access to an Orlando theme park</li>
          <li>Fairytale dining in a magical castle</li>
          <li>Wild Florida adventure package</li> 
        </>,
      legal: ''
    },
    {
      thumbnail: '/fun99/thumb-sarasota.jpg',
      title: <><span>The most fun</span> <span>weekend on the</span> <span>water</span></>,
      location: 'Sarasota, FL',
      imageAlt: 'Beautiful view of the ocean with seagulls flying above',
      details: 
        <>
          <li>Flight travel (private jet not included … maybe next time)</li> 
          <li>Three nights in a luxury villa</li>
          <li>Glass-bottom kayak night tour</li>
          <li>E-bike tour of the Key</li>
          <li>Parasailing</li>
          <li>Dolphin cruise</li>
          <li>Beach picnic</li>
        </>,
      legal: ''
    },
    {
      thumbnail: '/fun99/thumb-nyc.jpg',
      title: <><span>The most fun</span> <span>in the city</span> <span>that never sleeps</span></>,
      location: 'New York City, NY',
      imageAlt: 'Night scene of Times Square with taxis and people in the street',
      details:
        <>
          <li>Flight travel (private jet not included … maybe next time)</li>
          <li>Three nights at a luxury hotel</li>
          <li>NYC mafia and food tour</li>
          <li>Premium Broadway tickets to your choice of show</li>
          <li>Private helicopter tour</li>
        </>,
      legal: ''
    },
    {
      thumbnail: '/fun99/thumb-alaska.jpg',
      title: <><span>The most fun</span> <span>icy outdoor</span> <span>adventure</span></>,
      location: 'Anchorage, AK',
      imageAlt: 'Person standing on a paddle board in water surrounded by glaciers',
      details: 
      <>
        <li>Flight travel (private jet not included … maybe next time)</li>
        <li>Three nights at a luxury hotel</li>
        <li>Fat tire bike rentals </li>
        <li>Dog sledding experience</li>
        <li>Whale watch tour</li>
        <li>Glacier walk</li>
      </>,
      legal: ''
    },
    {
      thumbnail: '/fun99/thumb-arizona.jpg',
      title: <><span>The most fun</span> <span>R&R retreat</span><br/></>,
      location: 'Sedona, AZ',
      imageAlt: 'Person relaxing in a hot tub over-looking serene forest setting',
      details: 
        <>
          <li>Flight travel (private jet not included … maybe next time)</li>
          <li>Three nights at a luxury hotel</li>
          <li>60-minute spa treatment </li>
          <li>Daily wellness and exercise classes</li>
          <li>Starlight ride on Verde Canyon Railroad</li>
        </>,
      legal: ''
    }
  ]

  const scrollToContent = () => {
    const target = document.getElementById('sweeps-content');
    target.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.sonicfun99.com/scripts/buildiframe.js';
        script.setAttribute('data-mdmiframeurl', 'https://www.sonicfun99.com/');
        const scriptContainer = document.getElementById('script-container');
        scriptContainer.appendChild(script);
    
        return () => {
          scriptContainer.removeChild(script);
        };
      }, []);

  return (
    <div>
      <Helmet>
        <title>FUN IS ON THE MENU SWEEPSTAKE</title>
        <meta property="og:title" content="FUN IS ON THE MENU SWEEPSTAKES" />
        <meta property="og:description" content="Start living' free and enter to win one of the most fun trips on us." />
        <meta property="og:image" content="https://livefreeshopsonic.com/ui/favicon/fun99-og-image.jpg" />
      </Helmet>
      <div className='hero-container' aria-label="Primary" role="main">
        <a href="#sweeps-content" className='sr-only'>Skip to main content</a>
        <a href="#script-container" className='sr-only'>Skip to entry form</a>
        <img className='hero-image' src='/fun99/hero.jpg' width="2048" alt='Airplane parked in a Sonic Drive-In' />
        <img className='title-image' src='/fun99/headline.svg' width="1024" alt="Fun Is On The Menu - SWEEPSTAKES Powered by TripAdvisor" />
        <a className='scrolldown'
          tabIndex={0}
          onClick={scrollToContent}>
          <h1>HAVE SOME FUN</h1>
          <img 
            className='scrolldown-arrow'
            src="/ui/double-arrow-down.svg" 
            aria-label="scroll down to explore more" 
            alt="Scroll down" 
            width="60"  />
        </a>
      </div>
        <StaticBrandbar />
        <div className='sweeps-container' id="sweeps-content" aria-label="Secondary" role="main">
        <div className='content-container' style={{ maxWidth: '1200px' }}>
            <p>If you&rsquo;re not livin&rsquo; free, you&rsquo;re not having fun. And we want to give you the most fun trip on us.</p>
            <p>Three lucky SONIC fans will get the chance to choose one of five stupendously fun Tripadvisor® vacation packages. The sweepstakes closes at 11:59:59pm ET on September 23, 2024.</p>
          </div>
          <div className='scrolling-container'>
            <div className="horizontal-scroll-wrapper">
              <swiper-container
                navigation-next-el=".custom-next-button"
                navigation-prev-el=".custom-prev-button" 
                style={
                  {
                  "--swiper-navigation-color": "#fff", 
                  "--swiper-pagination-color": "#fff",
                  "--swiper-pagination-bottom": "0",
                  }
                }  
                slides-per-view={1} 
                pagination-clickable="true"
                navigation="true"
                loop="true"
                centered-slides="true"
                breakpoints={
                  JSON.stringify({
                      
                      425:{
                          slidesPerView: 1,
                      },
          
                      790: {
                          slidesPerView: 2,
                      },
          
                      1220: {
                          slidesPerView: 3,
                      },
          
                      2200: {
                          slidesPerView: 4,
                      }
                  })
              }
              >
               {vacationData.map((item, index) => (
                 <swiper-slide key={index}>
                  <div className='sweeps-item'>
                    <div className='sweeps-item-wrapper'>
                      <img src={item.thumbnail} className='thumbnail' alt={item.imageAlt} />
                      <div className='sweeps-item-deets'>
                        <h3 onClick={() => document.getElementById('script-container').scrollIntoView({ behavior: 'smooth' })}>{item.location}</h3>
                        <h2>{item.title}</h2>
                        <p><em>You and three guests will enjoy:</em></p>
                        <ul>{item.details}</ul>
                      </div>
                      <button className="sweeps-button" tabIndex={index + 2}
                        onClick={() => document.getElementById('script-container').scrollIntoView({ behavior: 'smooth' })}
                      ><span>ENTER TO WIN</span></button>
                    </div>
                  </div>
                </swiper-slide>
               ))}
              </swiper-container>
                <div className="nav-btn custom-prev-button">
                <svg viewBox="0 0 70.73 70.73">
                  <circle fill="#008ad8" strokeWidth="0" cx="35.37" cy="35.37" r="35.37"/>
                  <polyline fill="none" stroke="#ffffff" strokeMiterlimit="10" strokeWidth="5px" points="42.05 14.93 24.63 36.49 41.54 58.56"/>
                </svg>
                </div>
                <div className="nav-btn custom-next-button">
                  <svg viewBox="0 0 70.73 70.73">
                    <circle fill="#008ad8" strokeWidth="0" cx="35.37" cy="35.37" r="35.37"/>
                    <polyline fill="none" stroke="#ffffff" strokeMiterlimit="10" strokeWidth="5px" points="28.68 14.93 46.1 36.49 29.19 58.56"/>
                  </svg>
                </div>
            </div>
          </div>
        </div>
        <div id='script-container' className='sweeps-form' aria-label="Tertiary" role="main"></div>
        <StaticCallout 
            calloutText="AND IF ALL OF THIS SWEEPSTAKES TALK HAS YOU HUNGRY, GRAB A BITE FROM OUR FUN 1.99 MENU"
            calloutURL="https://www.sonicdrivein.com/menu/value-menu/"
            calloutLabel="ORDER NOW"
          />
    </div>
  );
}